import { makeVar } from '@apollo/client';

import AudioApi from '@phoenix7dev/audio-api';
import { getUserConfig } from '@phoenix7dev/utils-fe';

import type { SlotId } from '../config';
import { CardLevel } from '../config';
import {
  BonusStatus,
  GameMode,
  IBonus,
  ICardFeaturesData,
  ISettledBet,
  IUserBalance,
  ModalOpeningTypes,
  ReelSet,
  UserBonus,
} from '../global.d';
import { getBackground, getUnlockedCards, getUnlockedRewards } from '../utils';

import type { IBet, IConfig, ISlotConfig, IStressful } from './d';

export const setIsAuthorized = makeVar<boolean>(false);

export const setProgress = makeVar<{ status: number; wasLoaded?: boolean }>({
  status: 0,
  wasLoaded: false,
});

export const setIsLeftHandMode = makeVar<boolean>(getUserConfig<IConfig>('config', 'isLeftHandMode', false));

export const setIsSoundOn = makeVar<boolean>(getUserConfig<IConfig>('config', 'isSoundOn', true));

export const setIsShowSoundToast = makeVar<boolean>(AudioApi.isRestricted);

export const setIsSuspended = makeVar<boolean>(false);

export const setBrokenGame = makeVar<boolean>(false);

export const setBetResult = makeVar<ISettledBet | null>(null);

export const setUserLastBetResult = makeVar<IBet>({
  id: '',
  coinAmount: 1,
  coinValue: 1,
  result: {
    reelPositions: [0, 0, 0, 0, 0, 0],
    winCoinAmount: 0,
  },
  reelSetId: '',
  createdAt: '',
  updatedAt: '',
  data: {
    bonuses: [],
    features: {
      multiplier: 1,
    },
  },
  userBonus: {
    betId: undefined,
    bonusId: '',
    bonus: {
      id: '',
      coinAmount: 0,
    },
  },
});

export const setUserLastBonusBet = makeVar<IBet>({
  id: '',
  coinAmount: 1,
  coinValue: 1,
  result: {
    reelPositions: [0, 0, 0, 0, 0, 0],
    winCoinAmount: 0,
  },
  reelSetId: '',
  createdAt: '',
  updatedAt: '',
  data: {
    bonuses: [],
    features: {
      multiplier: 1,
    },
  },
  userBonus: {
    betId: undefined,
    bonusId: '',
    bonus: {
      id: '',
      coinAmount: 0,
    },
  },
});

export const setLastSpinData = makeVar<{
  reelPositions: number[];
  layout: SlotId[][];
}>({ reelPositions: [], layout: [] });

export const setIsFreeSpinsWin = makeVar<boolean>(false);

export const setReelSets = makeVar<ReelSet[]>([]);

export const setSoundValue = makeVar<number>(Number(setIsSoundOn()));

export const setIsRevokeThrowingError = makeVar<boolean>(false);

export const setIsSlotBusy = makeVar<boolean>(false);

export const setIsProceedToGame = makeVar<boolean>(false);

export const setBetAmount = makeVar<number>(20);

export const setCurrentReelSetId = makeVar<string>('');

export const setCurrentBonusId = makeVar<string | undefined>(undefined);

export const setCoinValue = makeVar<number>(1);

export const setCoinAmount = makeVar<number>(1);

export const setWinAmount = makeVar<number>(0);

export const setLastRegularWinAmount = makeVar<number>(0);

export const setAutoSpinsAmount = makeVar<number>(0);

export const setStopOnWinExceeds = makeVar<number>(0);

export const setStopOnWinExceedsSliderValue = makeVar<number>(1);

export const setStopOnBalanceIncreaseSliderValue = makeVar<number>(1);

export const setStopOnBalanceIncrease = makeVar<number>(0);

export const setStopOnBalanceDecrease = makeVar<number>(0);

export const setIsStopOnAnyWin = makeVar<boolean>(false);

export const setIsStopOnFeatureWin = makeVar<boolean>(true);

export const setIsStopOnWinExceeds = makeVar<boolean>(false);

export const setIsStopOnBalanceIncrease = makeVar<boolean>(false);

export const setIsStopOnBalanceDecrease = makeVar<boolean>(false);

export const setIsAutoSpins = makeVar<boolean>(false);

export const setAutoSpinsLeft = makeVar<number>(0);

// todo replace with real backend logic
export const setFreeSpinsTotalWin = makeVar<number>(0);

export const setGameMode = makeVar<GameMode>(GameMode.BASE_GAME);

export const setCurrency = makeVar<string>('FUN');

export const setUserBalance = makeVar<IUserBalance>({
  balance: { amount: 0, currency: '' },
  clientId: '',
  id: '',
});

export const setCurrentBonus = makeVar<UserBonus>({
  id: '',
  isActive: false,
  gameMode: GameMode.FREE_PACKS,
  data: {
    count: 0,
    creditMultiplier: 1,
    debitMultiplier: 1,
    storeCoinAmount: false,
    storeCoinValue: true,
    frbReferenceId: null,
  },
  coinAmount: 1,
  status: BonusStatus.INACTIVE,
  coinValue: 1,
  bonusId: '',
  roundsPlayed: 0,
  currentRound: 0,
  rounds: 8,
  reelSetId: '',
  totalWinAmount: 0,
  betId: '',
});

export const setFreePacksBonus = makeVar<UserBonus>({
  id: '',
  isActive: false,
  gameMode: GameMode.FREE_PACKS,
  data: {
    count: 0,
    creditMultiplier: 1,
    debitMultiplier: 1,
    storeCoinAmount: false,
    storeCoinValue: true,
    frbReferenceId: null,
  },
  status: BonusStatus.INACTIVE,
  coinAmount: 1,
  coinValue: 1,
  bonusId: '',
  roundsPlayed: 0,
  currentRound: 0,
  rounds: 0,
  reelSetId: '',
  totalWinAmount: 0,
  betId: '',
});

export const setMasterPacksBonus = makeVar<UserBonus>({
  id: '',
  isActive: false,
  gameMode: GameMode.MASTER_PACKS,
  data: {
    count: 0,
    creditMultiplier: 1,
    debitMultiplier: 1,
    storeCoinAmount: false,
    storeCoinValue: true,
    frbReferenceId: null,
  },
  coinAmount: 1,
  status: BonusStatus.INACTIVE,
  coinValue: 1,
  bonusId: '',
  roundsPlayed: 0,
  currentRound: 0,
  rounds: 0,
  reelSetId: '',
  totalWinAmount: 0,
  betId: '',
});

export const setAutoSpinsStartBalance = makeVar<number>(0);

export const setSlotConfig = makeVar<ISlotConfig>({
  previewImage: '',
  settings: {
    betLines: { max: 0, min: 0 },
    playerSettings: {
      betAmount: { min: 1, max: 1 },
      coinValue: {
        EUR: { min: 1, max: 1 },
        FUN: {
          min: 100,
          max: 100,
        },
      },
    },
    startPosition: [33, 17, 11, 19, 64],
  },
  data: {
    bonuses: [],
    features: {
      NM: 0,
      Np: 0,
      Nup: [],
      NupA: [],
      Nx: [],
      NxA: [],
      base: [],
    },
  },
  id: '812327ed-d1fe-4654-b7c6-8fc020b91325',
  lineSets: [
    {
      id: '',
      slotId: '',
      lines: [],
      coinAmountMultiplier: 0,
    },
  ],
  clientSettings: {
    coinAmounts: {
      default: [],
      quick: [],
    },
    coinValues: [],
    features: [],
    autoplay: {
      options: [],
      conditions: {
        stopOnAnyWin: {
          enabled: false,
        },
        stopIfFeatureIsWon: {
          enabled: false,
        },
        stopIfSingleWinExceeds: {
          enabled: false,
          multipliers: [],
        },
        stopIfBalanceDecreasesBy: {
          enabled: false,
          multipliers: [],
        },
        stopIfBalanceIncreasesBy: {
          enabled: false,
          multipliers: [],
        },
      },
    },
  },
  lines: [],
  sessionId: '',
  reels: [],
  icons: [],
  isBuyFeatureEnabled: true,
});

export const setBonuses = makeVar<IBonus[]>([]);

export const setGameHistory = makeVar<boolean[]>([false, false, false]);

export const setCurrentIsTurboSpin = makeVar<boolean>(false);

export const setIsTurboSpin = makeVar<boolean>(getUserConfig<IConfig>('config', 'isTurboSpin', false));

export const setIsEnabledSpaceSpin = makeVar<boolean>(getUserConfig<IConfig>('config', 'isEnabledSpaceSpin', true));

export const setSkipIntroScreen = makeVar<boolean>(getUserConfig<IConfig>('config', 'isSkipIntroScreen', false));
export const setStressful = makeVar<IStressful>({
  show: false,
  type: 'none',
  message: '',
});

export const setIsPopupOpened = makeVar<boolean>(false);
export const setIsOpenMenuModal = makeVar<{
  isOpen: boolean;
  type: 'menu' | 'info';
}>({
  isOpen: false,
  type: 'menu',
});
export const setIsOpenBetSettingsModal = makeVar<boolean>(false);

export const setIsOpenAutoplayModal = makeVar<boolean>(false);

export const setIsModalOpeningInProgress = makeVar<ModalOpeningTypes>(ModalOpeningTypes.NONE);

export const setIsDuringBigWinLoop = makeVar<boolean>(false);

export const setIsSoundLoading = makeVar<boolean>(false);

export const setPhoenixAnticipation = makeVar<boolean>(false);

export const setFreePacksCount = makeVar<number>(0);

export const setMasterPacksCount = makeVar<number>(0);

export const setSelectedRewardCard = makeVar<CardLevel>(getBackground('selectedRewardCard', CardLevel.Cm));

export const setSelectedRewardTable = makeVar<CardLevel>(getBackground('selectedRewardTable', CardLevel.Cm));

export const setBonusPackAutoPlay = makeVar<boolean>(false);

export const setCardsOpeningInprogress = makeVar<boolean>(false);

export const setUnlockedBackgrounds = makeVar<CardLevel[]>(
  getUnlockedRewards('selectedUnlockedBackgrounds', [CardLevel.Cm, CardLevel.Un]),
);

export const setEnabledCards = makeVar<SlotId[]>(getUnlockedCards('selectedUnlockedCards', []));

export const setCardsDisappeared = makeVar<boolean>(true);

export const setMasterPacksCountArray = makeVar<ICardFeaturesData[]>([]);

export const setFreePacksCountArray = makeVar<ICardFeaturesData[]>([]);

export const setFinalWinPopupVisible = makeVar<boolean>(false);

export const setIsModalVisible = makeVar<boolean>(false);

export const setIsResourceLoaded = makeVar<boolean>(false);

export const setFreeRoundsTotalWin = makeVar<number>(0);

export const setBottomContainerTotalWin = makeVar<number>(0);

export const setFreeRoundsBonus = makeVar<UserBonus>({
  id: '',
  isActive: false,
  gameMode: GameMode.FREE_ROUND_BONUS,
  data: {
    count: 0,
    creditMultiplier: 1,
    debitMultiplier: 1,
    storeCoinAmount: false,
    storeCoinValue: true,
    frbReferenceId: null,
  },
  coinAmount: 1,
  coinValue: 1,
  bonusId: '',
  roundsPlayed: 0,
  status: BonusStatus.INACTIVE,
  currentRound: 0,
  rounds: 8,
  reelSetId: '',
  totalWinAmount: 0,
  betId: '',
});
