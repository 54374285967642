/* eslint-disable security/detect-object-injection */
import { takeUntil } from 'rxjs';

import AudioApi from '@phoenix7dev/audio-api';

import {
  CardAbilitiesPattern,
  CardClass,
  CardLevel,
  ISongs,
  SlotId,
  eventPatternMappingsAnimationEnd,
  eventPatternMappingsAnimationStart,
} from '../../config';
import {
  BonusStatus,
  EnterProps,
  EventTypes,
  GameMode,
  ICardFeaturesData,
  ISettledBet,
  IUserBalance,
  UserBonus,
  bonusesId,
} from '../../global.d';
import {
  setAutoSpinsLeft,
  setBetAmount,
  setBetResult,
  setBonusPackAutoPlay,
  setBottomContainerTotalWin,
  setBrokenGame,
  setCardsDisappeared,
  setCardsOpeningInprogress,
  setCoinAmount,
  setCoinValue,
  setCurrentBonus,
  setCurrentBonusId,
  setFreePacksBonus,
  setFreeRoundsTotalWin,
  setFreeSpinsTotalWin,
  setGameHistory,
  setIsAutoSpins,
  setIsFreeSpinsWin,
  setIsProceedToGame,
  setIsSlotBusy,
  setLastRegularWinAmount,
  setLastSpinData,
  setMasterPacksBonus,
  setSkipIntroScreen,
  setSlotConfig,
  setUserBalance,
  setWinAmount,
} from '../../gql/cache';
import client from '../../gql/client';
import { getUserGql } from '../../gql/query';
import SlotMachine from '../../slotMachine';
import { PopupTypes, WinStages, eventManager } from '../../slotMachine/config';
import type { ICardAbility } from '../../slotMachine/d';
import IntroScreen from '../../slotMachine/introScreen/introScreen';
import { PopupController } from '../../slotMachine/popups/PopupController';
import { RewardAction, RewardsActions } from '../../slotMachine/popups/rewardsPopup/rewardsActions';
import { getBetResult, getWinStage, isRegularMode } from '../../utils';
import { States } from '../config';
import { Logic } from '../index';

import { Controller } from './Controller';

const processingOrder = [
  CardAbilitiesPattern.NM,
  CardAbilitiesPattern.Np,
  CardAbilitiesPattern.NupA,
  CardAbilitiesPattern.Nup,
  CardAbilitiesPattern.Nx,
  CardAbilitiesPattern.NxA,
] as CardAbilitiesPattern[];
export class BaseController extends Controller {
  public gameMode: GameMode = GameMode.BASE_GAME;

  public static the = new BaseController();

  private slotIdleTimeout: ReturnType<typeof setTimeout> | undefined;

  protected constructor() {
    super();
  }

  public override enterInitState(_prevState: States): void {
    if (!setSkipIntroScreen()) {
      Logic.the.changeState(States.INTRO);
      return;
    }
    if (setBrokenGame()) {
      Logic.the.changeState(States.BROKEN_GAME);
      return;
    }
    Logic.the.changeState(States.IDLE);
  }

  public override exitInitState(nextState: States): void {
    if (nextState === States.INTRO) return;

    SlotMachine.initSlotMachine(setSlotConfig());
    eventManager.emit(EventTypes.FORCE_RESIZE);
    if (nextState === States.IDLE) {
      setIsProceedToGame(true);
    }
  }

  public override enterIntroState(_prevState: States): void {
    IntroScreen.init();
    eventManager.emit(EventTypes.FORCE_RESIZE);
    eventManager.once(EventTypes.HANDLE_DESTROY_INTRO_SCREEN, () => {
      if (setBrokenGame()) {
        Logic.the.changeState(States.BROKEN_GAME);
        return;
      }
      Logic.the.changeState(States.IDLE);
    });
  }

  public override exitIntroState(_nextState: States): void {
    SlotMachine.initSlotMachine(setSlotConfig());
    eventManager.emit(EventTypes.FORCE_RESIZE);
  }

  public override enterBrokenGameState(_prevState: States): void {
    setIsProceedToGame(true);
    const bonus = setCurrentBonus();
    SlotMachine.the().onBrokenGame(bonus);
    Logic.the.changeState(States.TRANSITION);
    Logic.the.changeGameMode(bonus.gameMode, {
      bonus,
      immediate: true,
      skipIdle: bonus.gameMode === GameMode.FREE_ROUND_BONUS,
    });
    if (bonus.gameMode === GameMode.FREE_ROUND_BONUS) {
      eventManager.emit(EventTypes.DISABLE_BUY_FEATURE_BTN, true);
    }
  }

  public override enterIdleState(prevState: States): void {
    if (setIsAutoSpins() && setAutoSpinsLeft() <= 0) {
      setIsAutoSpins(false);
      setBonusPackAutoPlay(false);
      eventManager.emit(EventTypes.DISABLE_BUY_FEATURE_BTN, false);
    }
    if (prevState === States.PACK_OPEN) {
      eventManager.emit(EventTypes.DISABLE_BUY_FEATURE_BTN, false);
      setIsSlotBusy(false);
      return;
    }
    if (prevState === States.TRANSITION) {
      if (setCurrentBonusId()) {
        return;
      }
    }

    if (prevState === States.INIT || prevState === States.INTRO || prevState === States.BROKEN_GAME) {
      // const debug = new Debug();
      // debug.x = 800;
      // Logic.the.application.stage.addChild(debug);
      // Logic.the.application.ticker.add(() => debug.update());
      return;
    }
    // when FRB is expired, you dont have result
    // setBetResult is null and you have to call query
    // to update balance.
    if (!setBetResult()) {
      client
        .query<{ user: IUserBalance }>({
          query: getUserGql,
          fetchPolicy: 'network-only',
        })
        .then((userBalance) => {
          setUserBalance(userBalance.data.user);
          eventManager.emit(EventTypes.UPDATE_USER_BALANCE, userBalance.data.user.balance.amount);
        });
      return;
    }
    if (prevState === States.BEFORE_WIN) {
      const betResult = getBetResult(setBetResult());
      // its locally for testing.
      // betResult.bet.data.bonuses.push({
      //   ...(setCurrentBonus() as UserBonus),
      //   isActive: true,
      //   gameMode: GameMode.FREE_ROUND_BONUS,
      //   currentRound: 0,
      //   rounds: 5,
      //   totalWinAmount: 0,
      //   coinAmount: 1,
      //   coinValue: 1000,
      //   id: '65a57ad1-3fd9-45f7-a16b-e9f896ab3e55',
      //   isFreeBet: true,
      //   bonusId: '65a57ad1-3fd9-45f7-a16b-e9f896ab3e55',
      // });
      const frbBonus = betResult.bet.data.bonuses.find((e) => e.isFreeBet);
      if (frbBonus && frbBonus.status !== BonusStatus.SETTLED) {
        eventManager.emit(EventTypes.FORCE_STOP_AUTOPLAY);
        setCurrentBonus({
          ...frbBonus,
          gameMode: GameMode.FREE_ROUND_BONUS,
          rounds: frbBonus.rounds,
          isActive: true,
          currentRound: 0,
          coinAmount: frbBonus.coinAmount,
          coinValue: frbBonus.coinValue,
        });
        eventManager.emit(EventTypes.UPDATE_FREE_ROUNDS_LEFT, setCurrentBonus().rounds);
        Logic.the.changeState(States.TRANSITION);
        Logic.the.changeGameMode(GameMode.FREE_ROUND_BONUS);
        return;
      }
    }
    this.slotIdleTimeout = setTimeout(() => {
      AudioApi.play({ type: ISongs.BGM_BG_Melo_Loop });
    }, 20000);
    Logic.the.isReadyForFlip = false;
    setIsSlotBusy(false);
    eventManager.emit(EventTypes.DISABLE_BUY_FEATURE_BTN, false);
    eventManager.emit(EventTypes.UPDATE_USER_BALANCE, getBetResult(setBetResult()).balance.settled);
    this.handleHistory();
  }
  public override enterOpenPackState(_prevState: States): void {
    eventManager.emit(EventTypes.DISABLE_BUY_FEATURE_BTN, true);
    setIsSlotBusy(true);
    clearTimeout(this.slotIdleTimeout);
  }

  public override enterBeforeWinState(_prevState: States): void {
    Logic.the.isReadyForFlip = true;
    setIsSlotBusy(false);
  }

  public override enterWinPresentationState(_prevState: States): void {
    const betResult: ISettledBet = getBetResult(setBetResult());
    Logic.the.isReadyForFlip = false;
    const hasAbilities = betResult.bet.data.features.base.some((element) => element.abilities);
    if (hasAbilities) {
      Logic.the.changeState(States.APPEND_ABILITIES);
    } else {
      Logic.the.changeState(States.AFTER_WIN);
    }
  }

  private handleAbility = async (
    cardData: ICardFeaturesData,
    resolvePatternStep: () => void,
    lastCard: boolean,
    cardIndex: number,
    pattern: CardAbilitiesPattern,
    isLastPattern: boolean,
  ): Promise<void> => {
    return new Promise((resolve) => {
      const betResult: ISettledBet = getBetResult(setBetResult());
      const cardAbility: ICardAbility = cardData?.abilities?.find(
        (item: ICardAbility) => item.pattern === pattern,
      ) as ICardAbility;
      if (cardAbility) {
        let outId: string | number = 1;
        if (pattern !== CardAbilitiesPattern.Np && pattern !== CardAbilitiesPattern.NM) {
          if (pattern === CardAbilitiesPattern.NxA) {
            outId = betResult.bet.data.features[pattern].filter((e) => e.price).length;
          } else if (pattern === CardAbilitiesPattern.NupA) {
            outId = cardIndex + 1;
          } else {
            outId =
              betResult.bet.data.features[pattern].findIndex(
                (e) => e.initAbility.length && e.initAbility.find((e) => e.id === cardIndex),
              ) + 1;
          }
        }

        eventManager.emit(
          eventPatternMappingsAnimationStart[
            (pattern + '_' + (cardIndex + 1)) as keyof typeof eventPatternMappingsAnimationStart
          ] as unknown as string,
          pattern,
          cardIndex,
        );
        eventManager.on(
          eventPatternMappingsAnimationEnd[
            pattern as keyof typeof eventPatternMappingsAnimationEnd
          ] as unknown as string,
          (id: number) => {
            if (id === cardIndex) {
              PopupController.the.openPopup(PopupTypes.REWARD_POPUP, {
                pattern,
                value: cardAbility.value,
                inId: cardIndex + 1,
                outId,
              });
              eventManager.removeListener(
                eventPatternMappingsAnimationEnd[
                  pattern as keyof typeof eventPatternMappingsAnimationEnd
                ] as unknown as string,
              );
            }
          },
        );
        RewardsActions.the.rewardsPopupObservable
          .pipe(takeUntil(RewardsActions.the.destroyObservable))
          .subscribe((data: RewardAction) => {
            if (pattern !== CardAbilitiesPattern.NM && pattern !== CardAbilitiesPattern.Np) {
              betResult.bet.data.features[pattern].forEach((card, index) => {
                const newCard: {
                  id: number;
                  cardName: SlotId;
                  cardClass: CardClass;
                  cardLevel: CardLevel;
                  cardPrice: number;
                } = {
                  id: index,
                  cardName: card.name,
                  cardClass: card.class,
                  cardLevel: card.level,
                  cardPrice: card.price,
                };
                if (card.initAbility?.length) {
                  const ability = card.initAbility.find((e) => e.id === cardIndex);
                  if (ability) {
                    newCard.cardPrice = ability.price;
                    if (card.initAbility.length > 1) {
                      if (pattern === CardAbilitiesPattern.Nx) {
                        card.initAbility[1].price += ability.price;
                      } else if (pattern === CardAbilitiesPattern.NxA) {
                        const nX = betResult.bet.data.features['Nx'].find((e) => e.id === card.id);
                        if (nX?.initAbility?.length) {
                          card.initAbility[0].price += nX?.initAbility[0].price;
                        }
                        newCard.cardPrice = card.initAbility[0].price;
                        card.initAbility[1].price += card.initAbility[0].price;
                      } else if (pattern === CardAbilitiesPattern.Nup || pattern === CardAbilitiesPattern.NupA) {
                        newCard.cardName = (card.initAbility[0].value + newCard.cardClass) as SlotId;
                        newCard.cardLevel = card.initAbility[0].value;
                      }
                      card.initAbility = card.initAbility.splice(1);
                    } else if (card.initAbility.length === 1 && pattern === CardAbilitiesPattern.NxA) {
                      newCard.cardPrice = card.price;
                    }
                    eventManager.emit(EventTypes.UPDATE_CARD, newCard);
                  }
                }
              });
            }
            setTimeout(
              () => {
                resolve();
                if (lastCard) {
                  resolvePatternStep();
                  if (isLastPattern) {
                    Logic.the.changeState(States.AFTER_WIN);
                  }
                }
              },
              pattern === CardAbilitiesPattern.NxA || pattern === CardAbilitiesPattern.NupA ? 500 : 200,
            );
            if (data.index === cardIndex + 1 && data.pattern === pattern) {
              RewardsActions.the.unsubscribe();
            }
          });
      } else {
        resolve();
        if (lastCard) {
          resolvePatternStep();
          if (isLastPattern) {
            Logic.the.changeState(States.AFTER_WIN);
          }
        }
      }
    });
  };

  private handleAbilities = async (
    cardArr: ICardFeaturesData[],
    resolvePatternStep: () => void,
    pattern: CardAbilitiesPattern,
    isLastPattern: boolean,
  ) => {
    for (let i = 0; i < cardArr.length; i++) {
      const lastCard = i === cardArr.length - 1;
      await this.handleAbility(cardArr[i]!, resolvePatternStep, lastCard, i, pattern, isLastPattern);
    }
  };

  private processElement = async (pattern: CardAbilitiesPattern, isLastPattern: boolean): Promise<void> => {
    const betResult: ISettledBet = getBetResult(setBetResult());

    return new Promise((resolve) => {
      const hasAbilities = betResult.bet.data.features.base.find((element) => {
        return element.abilities && element.abilities.some((ability: ICardAbility) => ability.pattern === pattern);
      });
      if (hasAbilities) {
        if (pattern === CardAbilitiesPattern.NM) {
          const cardArr = betResult.bet.data.features.base;
          this.handleAbilities(cardArr, resolve, pattern, isLastPattern);
        }
        if (pattern === CardAbilitiesPattern.Np) {
          const cardArr = betResult.bet.data.features.base;
          this.handleAbilities(cardArr, resolve, pattern, isLastPattern);
        }
        if (pattern === CardAbilitiesPattern.Nup || pattern === CardAbilitiesPattern.NupA) {
          const cardArr = betResult.bet.data.features[pattern];
          this.handleAbilities(cardArr, resolve, pattern, isLastPattern);
        }
        if (pattern === CardAbilitiesPattern.Nx || pattern === CardAbilitiesPattern.NxA) {
          const cardArr = betResult.bet.data.features[pattern];
          this.handleAbilities(cardArr, resolve, pattern, isLastPattern);
        }
      } else {
        resolve();
        if (isLastPattern) {
          Logic.the.changeState(States.AFTER_WIN);
        }
      }
    });
  };

  private processElements = async () => {
    const totalElements = processingOrder.length;
    for (let i = 0; i < totalElements; i++) {
      const pattern = processingOrder[i];
      const isLastPattern = i === totalElements - 1;
      await this.processElement(pattern!, isLastPattern);
    }
  };

  public override enterAppendAbilitiesState(_prevState: States): void {
    this.processElements();
  }

  public override enterAfterWinState(_prevState: States): void {
    const betResult: ISettledBet = getBetResult(setBetResult());
    const { winCoinAmount } = getBetResult(setBetResult()).bet.result;
    if (getWinStage(winCoinAmount) >= WinStages.BigWin) {
      eventManager.once(EventTypes.END_BIG_WIN_PRESENTATION, () => {
        if (isRegularMode(Logic.the.controller.gameMode)) {
          setWinAmount(winCoinAmount);
          setLastRegularWinAmount(winCoinAmount);
        }
        Logic.the.changeState(States.JINGLE);
      });
      eventManager.emit(EventTypes.START_BIG_WIN_PRESENTATION, winCoinAmount);
    } else if (betResult.bet.result.winCoinAmount) {
      if (isRegularMode(Logic.the.controller.gameMode)) {
        setWinAmount(winCoinAmount);
        setLastRegularWinAmount(winCoinAmount);
      }
      eventManager.emit(EventTypes.START_COUNT_UP, 0, betResult.bet.result.winCoinAmount, 0);
      eventManager.once(EventTypes.COUNT_UP_END, () => {
        eventManager.emit(EventTypes.HIDE_COUNT_UP);
      });
      Logic.the.changeState(States.JINGLE);
    } else {
      Logic.the.changeState(States.JINGLE);
    }
  }

  public override enterJingleState(_prevState: States): void {
    setCardsOpeningInprogress(false);
    setCardsDisappeared(true);
    const betResult = getBetResult(setBetResult());

    const bonuses = betResult.bet.data.bonuses;

    // its locally for testing.
    // bonuses.push({
    //   ...(setCurrentBonus() as UserBonus),
    //   isActive: true,
    //   gameMode: GameMode.FREE_ROUND_BONUS,
    //   currentRound: 0,
    //   rounds: 5,
    //   totalWinAmount: 0,
    //   coinAmount: 1,
    //   coinValue: 1000,
    //   id: '65a57ad1-3fd9-45f7-a16b-e9f896ab3e55',
    //   isFreeBet: true,
    //   bonusId: '65a57ad1-3fd9-45f7-a16b-e9f896ab3e55',
    // });
    const freePacksBonus = bonuses.find((bonus) => bonus.bonusId === bonusesId[GameMode.FREE_PACKS]);
    const masterPacksBonus = bonuses.find((bonus) => bonus.bonusId === bonusesId[GameMode.MASTER_PACKS]);
    const freeRoundBonus = bonuses.find((e) => e.isFreeBet);
    // if we have only 1 bonus and its FRB.
    if (bonuses.length === 1 && freeRoundBonus) {
      eventManager.emit(EventTypes.FORCE_STOP_AUTOPLAY);
      setBottomContainerTotalWin(0);
      setFreeRoundsTotalWin(0);
      setCurrentBonus({
        ...(freeRoundBonus as UserBonus),
        gameMode: GameMode.FREE_ROUND_BONUS,
        rounds: freeRoundBonus.rounds,
        isActive: true,
        currentRound: 0,
        coinAmount: freeRoundBonus.coinAmount,
        coinValue: freeRoundBonus.coinValue,
      });
      eventManager.emit(EventTypes.UPDATE_FREE_ROUNDS_LEFT, setCurrentBonus().rounds);
      setCoinValue(freeRoundBonus.coinValue);
      setCoinAmount(freeRoundBonus.coinAmount);
      setBetAmount(freeRoundBonus.coinAmount * setSlotConfig().lineSets[0]!.coinAmountMultiplier);
      eventManager.emit(EventTypes.UPDATE_BET);
      Logic.the.changeState(States.TRANSITION);
      Logic.the.changeGameMode(GameMode.FREE_ROUND_BONUS, {
        bonus: freeRoundBonus as UserBonus,
      });
      return;
    }
    if (freePacksBonus) {
      setCurrentBonus({
        ...(freePacksBonus as UserBonus),
        isActive: true,
      });
      if (setAutoSpinsLeft() && setBonusPackAutoPlay()) {
        setAutoSpinsLeft(setFreePacksBonus().rounds + setMasterPacksBonus().rounds);
      }
      setFreeSpinsTotalWin(betResult.bet.result.winCoinAmount);
      setBottomContainerTotalWin(betResult.bet.result.winCoinAmount);
      Logic.the.changeState(States.TRANSITION);
      Logic.the.changeGameMode(GameMode.FREE_PACKS);
      eventManager.emit(EventTypes.UPDATE_SPIN_BUTTON_INTENT);
    } else if (masterPacksBonus) {
      setCurrentBonus({
        ...(masterPacksBonus as UserBonus),
        isActive: true,
      });
      if (setAutoSpinsLeft() && setBonusPackAutoPlay()) {
        setAutoSpinsLeft(setFreePacksBonus().rounds + setMasterPacksBonus().rounds);
      }
      setFreeSpinsTotalWin(betResult.bet.result.winCoinAmount);
      setBottomContainerTotalWin(betResult.bet.result.winCoinAmount);
      Logic.the.changeState(States.TRANSITION);
      Logic.the.changeGameMode(GameMode.MASTER_PACKS);
      eventManager.emit(EventTypes.UPDATE_SPIN_BUTTON_INTENT);
    } else {
      Logic.the.changeState(States.IDLE);
      eventManager.emit(EventTypes.UPDATE_SPIN_BUTTON_INTENT);
    }
  }

  public override enterController(prevGameMode: GameMode, _props?: EnterProps): void {
    if (prevGameMode !== GameMode.FREE_PACKS && prevGameMode !== GameMode.MASTER_PACKS) {
      eventManager.emit(EventTypes.BASE_GAME_PACKS);
    }
    if (!AudioApi.isPlaying(ISongs.BGM_BG_Melo_Loop)) {
      AudioApi.play({ type: ISongs.BGM_BG_Melo_Loop });
    }
    eventManager.emit(EventTypes.DISABLE_BUY_FEATURE_BTN, false);
    eventManager.on(EventTypes.HANDLE_BUY_BONUS, (bonusId: string) => {
      Logic.the.changeState(States.TRANSITION);
      Logic.the.changeGameMode(GameMode.BUY_FEATURE, { bonusId });
    });
    if (prevGameMode === null) return;
    setIsFreeSpinsWin(false);
    if (
      prevGameMode === GameMode.FREE_PACKS ||
      prevGameMode === GameMode.MASTER_PACKS ||
      prevGameMode === GameMode.FREE_ROUND_BONUS
    ) {
      setWinAmount(setBottomContainerTotalWin());
      setBottomContainerTotalWin(0);
      if (setBetResult()) {
        eventManager.emit(EventTypes.UPDATE_USER_BALANCE, getBetResult(setBetResult()).balance.settled);
      }
      this.handleHistory();
    }
    Logic.the.changeState(States.IDLE);
  }

  public override exitController(_nextGameMode: GameMode): void {
    clearTimeout(this.slotIdleTimeout);
    eventManager.removeListener(EventTypes.HANDLE_BUY_BONUS);
  }

  public override setResult(result: ISettledBet): void {
    if (isRegularMode(Logic.the.controller.gameMode)) {
      eventManager.emit(EventTypes.UPDATE_USER_BALANCE, result.balance.placed);
      setUserBalance({ ...setUserBalance(), balance: result.balance.placed });
    }

    setBetResult(result);
    setLastSpinData({
      layout: [],
      reelPositions: getBetResult(setBetResult()).bet.result.reelPositions,
    });

    if (!isRegularMode(Logic.the.controller.gameMode)) {
      setFreeSpinsTotalWin(setFreeSpinsTotalWin() + result.bet.result.winCoinAmount);
      setBottomContainerTotalWin(setBottomContainerTotalWin() + result.bet.result.winCoinAmount);
    }
  }

  private handleHistory(): void {
    const betResult = getBetResult(setBetResult());
    const win = betResult.bet.result.winCoinAmount;
    const lastThreeSpins = [...setGameHistory().slice(1), !!win];
    setGameHistory(lastThreeSpins);
    setUserBalance({ ...setUserBalance(), balance: betResult.balance.settled });
  }
}
