import AudioApi from '@phoenix7dev/audio-api';

import { ISongs } from '../../config';
import { BonusStatus, EventTypes, GameMode, UserBonus, bonusesId } from '../../global.d';
import {
  setAutoSpinsLeft,
  setBetResult,
  setBonusPackAutoPlay,
  setBottomContainerTotalWin,
  setBrokenGame,
  setCardsDisappeared,
  setCardsOpeningInprogress,
  setCurrentBonus,
  setFreePacksBonus,
  setFreePacksCount,
  setFreeRoundsBonus,
  setFreeRoundsTotalWin,
  setFreeSpinsTotalWin,
  setIsAutoSpins,
  setIsSlotBusy,
  setLastRegularWinAmount,
  setMasterPacksBonus,
  setMasterPacksCount,
  setSlotConfig,
} from '../../gql/cache';
import client from '../../gql/client';
import { getUserBonuses } from '../../gql/query';
import { eventManager } from '../../slotMachine/config';
import { getBetResult, isRegularMode } from '../../utils';
import { States } from '../config';
import { Logic } from '../index';

import { BaseController } from './BaseController';

export class FreePacksController extends BaseController {
  public override gameMode: GameMode = GameMode.FREE_PACKS;

  public static override the = new FreePacksController();

  protected constructor() {
    super();
  }

  public override enterIdleState(_prevState: States): void {
    setIsSlotBusy(false);
    if (!setFreePacksCount()) {
      setIsSlotBusy(true);
      const isFreeRoundsBonus = setFreeRoundsBonus().isActive;
      if (isFreeRoundsBonus) {
        setFreeRoundsTotalWin(setFreeSpinsTotalWin() + setFreeRoundsTotalWin());
        setBottomContainerTotalWin(setFreeRoundsTotalWin());
        // we check user bonuses to determine if frb is still active or not.
        client
          .query<{ userBonuses: UserBonus[] }>({
            query: getUserBonuses,
            variables: {
              input: { status: BonusStatus.ACTIVE, slotId: setSlotConfig().id },
            },
            fetchPolicy: 'network-only',
          })
          .then((bonuses) => {
            const frbBonus = bonuses.data.userBonuses.find(
              (e) => e.bonusId === bonusesId[GameMode.FREE_ROUND_BONUS],
            ) as UserBonus;
            this.endFreePackBonus(true, !!frbBonus);
          });
      } else {
        this.endFreePackBonus(false, false);
      }
    }
  }

  public override enterOpenPackState(_prevState: States): void {
    setFreePacksCount(setFreePacksCount() - 1);
    eventManager.emit(EventTypes.UPDATE_WIN_FREE_PACKS);
    setIsSlotBusy(true);
  }

  public override enterJingleState(_prevState: States): void {
    setCardsDisappeared(true);
    setCardsOpeningInprogress(false);
    eventManager.emit(EventTypes.UPDATE_SPIN_BUTTON_INTENT);
    if (setBottomContainerTotalWin() > 0) {
      eventManager.emit(EventTypes.UPDATE_TOTAL_WIN_VALUE, setBottomContainerTotalWin());
    }
    const betResult = getBetResult(setBetResult());
    const bonuses = betResult.bet.data.bonuses;
    const freePacksBonus = bonuses.find((bonus) => bonus.bonusId === bonusesId[GameMode.FREE_PACKS]);
    const masterPacksBonus = bonuses.find((bonus) => bonus.bonusId === bonusesId[GameMode.MASTER_PACKS]);

    if (freePacksBonus) {
      setFreePacksBonus({
        ...(freePacksBonus as UserBonus),
      });
      setCurrentBonus({
        ...setFreePacksBonus(),
        isActive: true,
      });
      setFreePacksCount(setFreePacksBonus().rounds);
      if (setAutoSpinsLeft() && setBonusPackAutoPlay()) {
        setAutoSpinsLeft(setFreePacksBonus().rounds + setMasterPacksBonus().rounds);
      }
      eventManager.emit(EventTypes.UPDATE_WIN_FREE_PACKS);
    }
    if (masterPacksBonus) {
      setMasterPacksBonus({
        ...(masterPacksBonus as UserBonus),
      });
      setMasterPacksCount(setMasterPacksBonus().rounds);
      eventManager.emit(EventTypes.UPDATE_WIN_MASTER_PACKS);
    }
    if (!setFreePacksCount()) {
      if (setMasterPacksCount()) {
        setCurrentBonus({
          ...(setMasterPacksBonus() as UserBonus),
          isActive: true,
        });
        if (setAutoSpinsLeft() && setBonusPackAutoPlay()) {
          setAutoSpinsLeft(setFreePacksBonus().rounds + setMasterPacksBonus().rounds);
        }
        Logic.the.changeState(States.TRANSITION);
        Logic.the.changeGameMode(GameMode.MASTER_PACKS);
        return;
      }
      if (setIsAutoSpins() && setBonusPackAutoPlay()) {
        eventManager.emit(EventTypes.FORCE_STOP_AUTOPLAY);
        setBonusPackAutoPlay(false);
        if (!setIsSlotBusy() && !setCardsOpeningInprogress()) {
          eventManager.emit(EventTypes.DISABLE_BUY_FEATURE_BTN, false);
        }
      }
      Logic.the.changeState(States.IDLE);
    } else {
      Logic.the.changeState(States.IDLE);
    }
  }

  public override enterController(_prevGameMode: GameMode): void {
    if (_prevGameMode !== GameMode.MASTER_PACKS) {
      AudioApi.stop({ type: ISongs.BGM_BG_Melo_Loop });
      AudioApi.play({ type: ISongs.BGM_BP_Loop });
    }
    if (setBrokenGame() && setFreePacksCount()) {
      eventManager.emit(EventTypes.ADD_WIN_FREE_PACKS);
    }
    Logic.the.changeState(States.IDLE);
    if (setBrokenGame()) AudioApi.play({ type: ISongs.BGM_BP_Loop });
    setIsSlotBusy(false);
    if (setBottomContainerTotalWin() > 0) {
      eventManager.emit(EventTypes.UPDATE_TOTAL_WIN_VALUE, setBottomContainerTotalWin());
    }
  }

  public override exitController(nextGameMode: GameMode): void {
    setLastRegularWinAmount(setFreeSpinsTotalWin());
    if (isRegularMode(nextGameMode)) {
      AudioApi.stop({ type: ISongs.BGM_BP_Loop });
    }
    if (setBrokenGame()) setBrokenGame(false);
    setCurrentBonus({ ...setCurrentBonus(), isActive: false });
  }

  private endFreePackBonus(wasActive: boolean, isActive: boolean): void {
    eventManager.once(EventTypes.HIDE_FINAL_WIN_POPUP, () => {
      Logic.the.changeState(States.TRANSITION);
      if (wasActive) {
        Logic.the.changeGameMode(GameMode.FREE_ROUND_BONUS, { bonus: setCurrentBonus(), endBonus: !isActive });
      } else {
        Logic.the.changeGameMode(GameMode.BASE_GAME);
      }
      setIsSlotBusy(false);
      setFreeSpinsTotalWin(0);
      eventManager.emit(EventTypes.UPDATE_USER_BALANCE, getBetResult(setBetResult()).balance.settled);
    });
    setTimeout(() => {
      eventManager.emit(EventTypes.SHOW_FINAL_WIN_POPUP, setFreeSpinsTotalWin(), true);
    }, 200);
  }
}
